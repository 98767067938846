import React, { useState } from "react";
import {
  gql,
  useMutation,
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";

const SContactForm = ({ noLine, hidePaddingAndText, servicesData}) => {
  const APOLLO_CLIENT = new ApolloClient({
    uri: process.env.GATSBY_API_URL,
    cache: new InMemoryCache(),
  });

  const Form = () => {
    const messageRef = React.useRef(null);

    function handleEmailChange(event) {
      const value = event.target.value;
      const error = validateEmail(value);
      setEmailValue(value);
      setEmailError(error);
    }

    function validateEmail(value) {
      let error;
      if (!value) {
        error = "Email is Required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = "Invalid email address";
      }
      return error;
    }

    const [fullNameValue, setFullNameValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [messageValue, setMessageValue] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobileValue, setMobileValue] = useState("");

    const CONTACT_MUTATION = gql`
      mutation CreateSubmissionMutation(
        $clientMutationId: String!
        $full_name: String!
        $email: String!
        $message: String!
        $mobile: String!
      ) {
        createSubmission(
          input: {
            clientMutationId: $clientMutationId
            full_name: $full_name
            email: $email
            message: $message
            mobile: $mobile
          }
        ) {
          success 
          data
        }
      }
    `;
    const [mutateFunction, { data, loading, error }] =
      useMutation(CONTACT_MUTATION);

    return (
      <section id="contact" className="contact-sec section-padding position-re">
        <div className="container">
          <div id="form-text" className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head  text-center">
                <h6 className="wow fadeIn" data-wow-delay=".5s">
                  {
                    servicesData && servicesData[0]?.title? `Looking for ${servicesData[0].title} Solutions` : "Contact Us"
                  }
                </h6>
                <h4 className="wow color-font">
                Before we start, we would like to better understand your needs. We'll review your application.
                </h4>
              </div>
            </div>
          </div>

          <div id="form-fields" className="row justify-content-center">
            <div className="col-lg-10">
              <div className="form wow fadeInUp" data-wow-delay=".5s">
                <form
                  id="contact-form"
                  onSubmit={async (event) => {
                    event.preventDefault();
                    mutateFunction({
                      variables: {
                        clientMutationId: "example",
                        full_name: fullNameValue,
                        email: emailValue,
                        message: messageValue,
                        mobile: mobileValue,
                      },
                    });
                  }}
                >
                  <div className="messages" ref={messageRef}></div>
                  <div className="controls">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            id="form_name"
                            type="text"
                            name="name"
                            placeholder="Name"
                            required="required"
                            onChange={(event) => {
                              setFullNameValue(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            id="form_mobile"
                            type="number"
                            name="mobile"
                            placeholder="Mobile"
                            required="required"
                            onChange={(event) => {
                              setMobileValue(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            // validate={validateEmail}
                            id="form_email"
                            type="email"
                            name="email"
                            placeholder="Email"
                            required="required"
                            onChange={handleEmailChange}
                            value={emailValue}
                            // onChange={event => {
                            //   setEmailValue(event.target.value)
                            // }}
                          />
                          {emailError && (
                            <small className="text-danger">
                              <i>{emailError}</i>
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <input
                            as="textarea"
                            id="form_message"
                            name="message"
                            placeholder="Message"
                            rows="4"
                            required="required"
                            onChange={(event) => {
                              setMessageValue(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="text-center">
                          <button
                            type="submit"
                            className="nb butn bord curve mt-30"
                          >
                            <span>Send Message</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div>
                  {loading && <p>Loading ...</p>}
                  {error && (
                    <p>
                      An unknown error has occured, please try again later ...
                    </p>
                  )}
                  {data && data.createSubmission.success && (
                    <p>Message sent successfully!</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!noLine ? <div className="line bottom left"></div> : ""}
      </section>
    );
  };

  return (
    <ApolloProvider client={APOLLO_CLIENT}>
      <Form />
      <style>
        {hidePaddingAndText &&
          `
          h3.wow.color-font, 
          h6.wow.fadeIn{
            display:none;
          }
          
          #form-text{
            display:none;
          }

          .contact-sec.section-padding.position-re{
            padding:0px;
            }
            
          .line.bottom.left{
            display:none;
          }

          div#form-fields.row.justify-content-center {
            -webkit-box-pack: start!important;
            justify-content: flex-start!important;
          }

          #form-fields div.col-lg-6,.col-lg-12,.col-12{
            padding:0px 10px 0px 0px!important;
          }
        }
        `}
      </style>
    </ApolloProvider>
  );
};

export default SContactForm;
